@use '@angular/material' as mat;
@use './m3-theme.scss' as palette;
@use './_custom_components.scss' as overrides;
@use './extended-palette.scss' as extendedPalette;

@include mat.core();

@mixin light-theme {
  @include mat.all-component-themes(palette.$light-theme);
  @include mat.core-theme(palette.$light-theme);
  @include overrides.typography-overrides();
  @include mat.system-level-colors(palette.$light-theme);
  @include mat.typography-hierarchy(palette.$light-theme);
  @include mat.color-variants-backwards-compatibility(palette.$light-theme);
  @include overrides.mat-overrides();
  @include mat.system-level-typography(palette.$light-theme); 
  @include mat.all-component-typographies(palette.$light-theme);
  @include overrides.light-theme-palette-overrides();
  @include extendedPalette.extended-light-theme();

}

@mixin dark-theme {
  @include mat.all-component-themes(palette.$dark-theme);
  @include mat.core-theme(palette.$dark-theme);
  @include overrides.typography-overrides();
  @include mat.system-level-colors(palette.$dark-theme);
  @include mat.typography-hierarchy(palette.$dark-theme);
  @include mat.color-variants-backwards-compatibility(palette.$dark-theme);
  @include overrides.mat-overrides();
  @include mat.system-level-typography(palette.$dark-theme); 
  @include mat.all-component-typographies(palette.$dark-theme);
  @include overrides.dark-theme-palette-overrides();
  @include extendedPalette.extended-dark-theme();

}


@media screen and (min-width: 900px) {

  body,
  html {
    font-family: 'Inter';
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
    outline: 0 !important;
    height: 100% !important;
    letter-spacing: 0.01rem !important;
  }
}



body.light-theme {
  @include light-theme;
}

body.dark-theme {
  @include dark-theme;
}