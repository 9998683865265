@use 'sass:map';
@use '@angular/material' as mat;

$extended-palette-seed-colors: (
  warn: #E07B3C,
  success: #2A8659,
  info: #4A93D1
);

$extended-palettes: (
  warn: (
    0: #000000,
    20: #532200,
    30: #763300,
    40: #9A4605,
    80: #FFB68E,
    90: #FFDBCA,
    100: #ffffff
  ),
  success: (
    0: #000000,
    20: #003920,
    30: #005231,
    40: #006D43,
    80: #76DAA1,
    90: #A5F3C3,
    100: #ffffff
  ),
  info: (
    0: #000000,
    20: #003354,
    30: #2a79b6,
    40: #005486,
    80: #98cbff,
    90: #2a79b6,
    100: #ffffff
  ),
);

@mixin _generate-extended-light-theme($palette-name: string) {
  --sys-#{$palette-name}: #{map.get($extended-palette-seed-colors, $palette-name)};
  --sys-on-#{$palette-name}: #{map.get(map.get($extended-palettes, $palette-name), 100)};
  --sys-#{$palette-name}-container: #{map.get(map.get($extended-palettes, $palette-name), 90)};
  --sys-on-#{$palette-name}-container: #{map.get(map.get($extended-palettes, $palette-name), 100)};
}

@mixin _generate-extended-dark-theme($palette-name: string) {
  --sys-#{$palette-name}: #{map.get(map.get($extended-palettes, $palette-name), 80)};
  --sys-on-#{$palette-name}: #{map.get(map.get($extended-palettes, $palette-name), 20)};
  --sys-#{$palette-name}-container: #{map.get(map.get($extended-palettes, $palette-name), 30)};
  --sys-on-#{$palette-name}-container: #{map.get(map.get($extended-palettes, $palette-name), 100)};
}

@mixin _generate-extended-light-themes($palette-names) {
  @each $palette-name in $palette-names {
    @include _generate-extended-light-theme($palette-name);
  }
}

@mixin _generate-extended-dark-themes($palette-names) {
  @each $palette-name in $palette-names {
    @include _generate-extended-dark-theme($palette-name);
  }
}

@mixin extended-light-theme {
  @each $palette-name, $seed-color in $extended-palette-seed-colors {
    @include _generate-extended-light-theme($palette-name);
  }
}

@mixin extended-dark-theme {
  @each $palette-name, $seed-color in $extended-palette-seed-colors {
    @include _generate-extended-dark-theme($palette-name);
  }
}